.noiseBgLight {
  filter: contrast(170%) brightness(100%);
  isolation: isolate;
  background:
    linear-gradient(
      35deg,
      rgb(126 74 255 / 50%) 0%,
      rgb(70 0 250 / 51%) 21%,
      rgb(70 0 250 / 51%) 90%,
      rgb(95 32 255 / 50%) 100%
    ),
    url("/assets/images/noise.svg") repeat;
  mix-blend-mode: multiply;
}

.noiseBgDark {
  background:
    url("/assets/images/noise.svg"), 
    radial-gradient(217.61% 66.86% at 102.34% 84.42%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(131.42% 55.31% at -2.19% 19.69%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%),
    #1f293d;
  background-blend-mode: multiply, overlay, overlay, normal;
}

@media screen and (max-width: 480px) {
  .noiseBgDark {
  background:
    url("/assets/images/noise.svg") 0/100% 100%, 
    radial-gradient(217.61% 66.86% at 102.34% 84.42%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(131.42% 55.31% at -2.19% 19.69%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%),
    #1f293d;
  background-blend-mode: multiply, overlay, overlay, normal;
}

}

.wavesBg {
  background:
    url("/assets/images/waves_bg.webp") 20% 55% / 250% no-repeat;
  mix-blend-mode: overlay;
}

.brandBg {
  background:
    url("/assets/images/brand-bg.webp") no-repeat;
}
