.noiseBgLight {
  filter: contrast(170%) brightness(100%);
  isolation: isolate;
  background:
    linear-gradient(
      35deg,
      #F500CE 0%,
      #F500CE 21%,
      #F500CE 90%,
      #F500CE 100%
    );
    /* url("/assets/images/noise.svg") repeat; */
  mix-blend-mode: multiply;
}

.noiseBgDark {
  background:
    /* url("/assets/images/noise.svg"),  */
    radial-gradient(217.61% 66.86% at 102.34% 84.42%, #F500CE 0%, #F500CE 100%),
    radial-gradient(131.42% 55.31% at -2.19% 19.69%, #F500CE 0%, #F500CE 100%);
  background-blend-mode: soft-light;
}

@media screen and (max-width: 480px) {
  .noiseBgDark {
  background:
    /* url("/assets/images/noise.svg") 0/100% 100%,  */
    radial-gradient(217.61% 66.86% at 102.34% 84.42%, #F500CE 0%, #F500CE 100%),
    radial-gradient(131.42% 55.31% at -2.19% 19.69%, #F500CE 0%, #F500CE 100%);
  background-blend-mode: soft-light;
}

}

.wavesBg {
  background:
    url("/assets/images/waves_bg.webp") 50% 50% / 250% no-repeat;
  mix-blend-mode: saturation;
}
