.pin-spacer {
    width: 100% !important;
}

/* #jumplist-pin-spacer {
    width: 100% !important;
} */

.contact-form-container {
    background-size: 80px 80px;
    background-image:
        linear-gradient(to right, #FEF2FD 1px, transparent 1px),
        linear-gradient(to bottom, #FEF2FD 1px, transparent 1px);
}
.contact-form {
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 63.81%, rgba(255, 255, 255, 0) 100%);
}